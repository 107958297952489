import type { StateCreator } from "zustand";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { devtools } from "zustand/middleware";

const OWNUSER_UPDATE = "cms:ownuser:update" as const;
const USER_CREATE = "cms:users:create" as const;
const USER_READ = "cms:users:read" as const;
const USER_UPDATE = "cms:users:update" as const;
const USER_DELETE = "cms:users:delete" as const;
const TENANTS_READ = "cms:tenants:read" as const;
const TENANTTOKENS_CREATE = "cms:tenanttokens:create" as const;
const ROLES_READ = "cms:roles:read" as const;
const ROLES_CREATE = "cms:roles:create" as const;
const ROLES_UPDATE = "cms:roles:update" as const;
const ROLES_DELETE = "cms:roles:delete" as const;
const EDUCATIONAL_PROGRAMS_READ = "cms:educationalprograms:read" as const;
const EDUCATIONAL_PROGRAMS_CREATE = "cms:educationalprograms:create" as const;
const EDUCATIONAL_PROGRAMS_UPDATE = "cms:educationalprograms:update" as const;
const EDUCATIONAL_PROGRAMS_DELETE = "cms:educationalprograms:delete" as const;
const PRODUCT_READ = "cms:products:read" as const;
const PRODUCT_CREATE = "cms:products:create" as const;
const PRODUCT_UPDATE = "cms:products:update" as const;
const PRODUCT_DELETE = "cms:products:delete" as const;
const GROUP_READ = "cms:groups:read" as const;
const GROUP_CREATE = "cms:groups:create" as const;
const GROUP_UPDATE = "cms:groups:update" as const;
const GROUP_DELETE = "cms:groups:delete" as const;
const EDUCATIONAL_PATH_READ = "cms:educationalpaths:read" as const;
const EDUCATIONAL_PATH_CREATE = "cms:educationalpaths:create" as const;
const EDUCATIONAL_PATH_UPDATE = "cms:educationalpaths:update" as const;
const EDUCATIONAL_PATH_DELETE = "cms:educationalpaths:delete" as const;
const MODULES_READ = "cms:modules:read" as const;
const MODULES_CREATE = "cms:modules:create" as const;
const MODULES_UPDATE = "cms:modules:update" as const;
const MODULES_DELETE = "cms:modules:delete" as const;
const DIDACTIC_TOOLS_READ = "cms:didactictools:read" as const;
const DIDACTIC_TOOLS_CREATE = "cms:didactictools:create" as const;
const DIDACTIC_TOOLS_UPDATE = "cms:didactictools:update" as const;
const DIDACTIC_TOOLS_DELETE = "cms:didactictools:delete" as const;
const REFERENCE_CARDS_CREATE = "cms:referencecards:create" as const;
const REFERENCE_CARDS_READ = "cms:referencecards:read" as const;
const REFERENCE_CARDS_UPDATE = "cms:referencecards:update" as const;
const REFERENCE_CARDS_DELETE = "cms:referencecards:delete" as const;
const SHORTCODE_CREATE = "cms:shortcodes:create" as const;
const SHORTCODE_READ = "cms:shortcodes:read" as const;
const SHORTCODE_UPDATE = "cms:shortcodes:update" as const;
const SHORTCODE_DELETE = "cms:shortcodes:delete" as const;
const TENANTS_UPDATE = "cms:tenants:update" as const;

export const Scope = {
    OWNUSER_UPDATE,
    USER_CREATE,
    USER_READ,
    USER_UPDATE,
    USER_DELETE,
    TENANTS_UPDATE,
    TENANTS_READ,
    TENANTTOKENS_CREATE,
    ROLES_READ,
    ROLES_CREATE,
    ROLES_UPDATE,
    ROLES_DELETE,
    EDUCATIONAL_PROGRAMS_READ,
    EDUCATIONAL_PROGRAMS_CREATE,
    EDUCATIONAL_PROGRAMS_UPDATE,
    EDUCATIONAL_PROGRAMS_DELETE,
    PRODUCT_READ,
    PRODUCT_CREATE,
    PRODUCT_UPDATE,
    PRODUCT_DELETE,
    GROUP_READ,
    GROUP_CREATE,
    GROUP_UPDATE,
    GROUP_DELETE,
    EDUCATIONAL_PATH_READ,
    EDUCATIONAL_PATH_CREATE,
    EDUCATIONAL_PATH_UPDATE,
    EDUCATIONAL_PATH_DELETE,
    MODULES_READ,
    MODULES_CREATE,
    MODULES_UPDATE,
    MODULES_DELETE,
    DIDACTIC_TOOLS_READ,
    DIDACTIC_TOOLS_CREATE,
    DIDACTIC_TOOLS_UPDATE,
    DIDACTIC_TOOLS_DELETE,
    REFERENCE_CARDS_READ,
    REFERENCE_CARDS_CREATE,
    REFERENCE_CARDS_UPDATE,
    REFERENCE_CARDS_DELETE,
    SHORTCODE_CREATE,
    SHORTCODE_READ,
    SHORTCODE_UPDATE,
    SHORTCODE_DELETE,
} as const;

export type Scope = (typeof Scope)[keyof typeof Scope];

interface PermissionsStoreProps {
    ready: boolean;
    scopes: Scope[];
}

interface PermissionsStoreState extends PermissionsStoreProps {
    setReady: (ready: boolean) => void;
    hasPermission: (scope: Scope | Scope[]) => boolean;
    setScopes: (scopes: Scope[]) => void;
}

const createPermissionsStore: StateCreator<PermissionsStoreState> = (
    set,
    get,
) => ({
    ready: false,
    setReady: (ready) => set({ ready }),
    scopes: [],
    setScopes: (scopes) => set({ scopes }),
    hasPermission: (scope) => {
        const { scopes } = get();
        if (Array.isArray(scope)) {
            return scope.every((s) => scopes.includes(s));
        }
        return scopes.includes(scope);
    },
});

export const usePermissionsStore = create<PermissionsStoreState>()(
    devtools(
        persist(createPermissionsStore, {
            name: "witty/permissions",
            storage: createJSONStorage(() => sessionStorage),
            partialize: (state) => ({
                scopes: state.scopes,
            }),
        }),
    ),
);
